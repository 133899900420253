var isMobile = false; //initiate as false // device detection
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;

if(isMobile){
    $('body').addClass('mobile');
}
/*
function resize()
{
    var heights = window.innerHeight;
    $(".header").height(heights);
}
resize();
window.onresize = function() {
    resize();
};
*/

$(window).scroll(function () {
    var scrollTop = $(window).scrollTop();
    var height = $(window).height();
    $('.header.one').css({
        'opacity': ((height - scrollTop) / height)
    }); 

    $('.header.one .textholder').css({
        'opacity': ((height - scrollTop*1.5) / height) -0.2
    }); 
   
});


var jcmixloaded = false;
$(window).on('DOMContentLoaded load resize scroll', function(){
    if(inview() && !jcmixloaded){
        jcmixloaded = true;
        run();
    }
});


function inview(){
    //Window Object
    var win = $(window);
    //Object to Check
    obj = $('#jcmix');
    //the top Scroll Position in the page
    var scrollPosition = win.scrollTop();
    //the end of the visible area in the page, starting from the scroll position
    var visibleArea = win.scrollTop() + win.height();
    //the end of the object to check
    var objEndPos = (obj.offset().top );
    return(visibleArea >= objEndPos && scrollPosition <= objEndPos ? true : false)
}




$('.arrow').click(function(){
    $('html, body').animate({scrollTop:window.innerHeight}, 1000);
})


$(function(){
    var container = $('.tvgrid');

    container.imagesLoaded(function(){  
        $('.tvgrid-item img').css('opacity',1);
        container.masonry({
           itemSelector: '.tvgrid-item',
           columnWidth: function( containerWidth ) {
              return containerWidth /4;// depends how many boxes per row
            }(), // () to execute the anonymous function right away and use its result
            isAnimated: true
        });
    });

    var filmcontainer = $('.filmgrid');

    filmcontainer.imagesLoaded(function(){  
        $('.filmgrid-item img').css('opacity',1);
        filmcontainer.masonry({
           itemSelector: '.filmgrid-item',
           columnWidth: function( containerWidth ) {
              return containerWidth /4;// depends how many boxes per row
            }(), // () to execute the anonymous function right away and use its result
            isAnimated: true
        });
    });


    var gallerycontainer = $('.gallery');

    gallerycontainer.imagesLoaded(function(){  
        $('a img').css('opacity',1);
        gallerycontainer.masonry({
           itemSelector: '.gallery-item',
           columnWidth: function( containerWidth ) {
              return containerWidth /4;// depends how many boxes per row
            }(), // () to execute the anonymous function right away and use its result
            isAnimated: true
        });
    });
});



$('.creditsbutton').click(function(e){

    e.preventDefault();

    $('.credits').slideToggle(function(){

        if($('.credits').is(":visible"))
            $('button.creditsbutton').html('Hide Music Credit List');
        else
            $('button.creditsbutton').html('Show Music Credit List');

    });

});

function isElementInViewport (el) {

    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
}
$(document).ready(function() {
  $('.iframe-link').magnificPopup({type:'iframe'});
  $('.image-link').magnificPopup({type:'image'});
});


