//v1.0.3
//31/05/2016
var Jcmix = function() {
    //DOM


    this.JCmix               = $('#jcmix');
    this.DOMbody             = $('body'); 
    this.DOMhead             = $('head');
    this.DOMdocument         = $(document);
    this.playButton;    // DOM for transport play button
    this.startButton;   // DOM for transport back to beginning button
    this.recordButton;   // DOM for transport back to beginning button
    this.toggleRecordingsButton;
    this.clearRecordingsButton;
    this.recordinglist;
    this.channelStrip;  // DOM for channel strip
    this.timer;         // DOM for timer
    this.timerProgress; // DOM for progres time of track
    this.timerTotal;    // DOM for total time of track
    this.transport      // DOM for transport pannel
    this.timeline;      // DOM for transport timeline
    this.progressbar;   // DOM for transport timeline progress bar
    this.percentLoaded; // the ammount loaded (initial load)
    this.loader;        // the initial loader
    this.mixer;         // DOM for mixer
    
    ////////////////////////////////////////////////////////////////////////
    
    
    //////////////////////////////////////////////////////
    
    // LITERALS
    
    this.meterHeight         = 400; // height of meter in pixels (you also need ot change height var in scss)
    this.meterWidth          = 10; // height of meter in pixels
    this.stylesheetURL       = "assets/css/jcmix.min.css"; // Location of stylesheet
    
    
    // VARS
    this.recording = false; // toggle recording
    this.allowRecord = false; // toggle recording
    this.title;
    this.rec = false; // recordin gelement
    this.loop                = 0; // count loops
    this.currentLoopProgress = 0; // progress through each loop in milliseconds
    this.totalLength         = 0; // total length of track
    this.timelineClickPaused = false // only allow timeline to be updated once every few seconds
    this.replay              = false;
    this.replayFrom          = false;
    this.fingerPosition          = 0;
    
    this.loading             = true; // the app is loading
    this.ajaxURL             = false;
    this.loading             = 0;
    this.playing             = []; // whuich are currently playing
    this.context;
    this.bufferLoader;
    this.javascriptNode      = [];
    this.leftAnalysers       = [];
    this.rightAnalysers      = [];
    this.splitters           = [];
    this.gainNodes           = []; // the gain nodes themselves
    this.pannerNodes           = []; // the gain nodes themselves
    this.gainValues          = []; // gain value to resume to after unmute
    this.muted               = []; // bool if track is muted
    this.timelinedrag        = false; // don't update timeline if dragging progress bar
    this.masterGainNodes;
    
    this.urlList             = [];            // The list of tracks to be loaded
    this.bufferList          = [];
    this.loadCount           = 0; // the number of tracks loaded
    
    this.startedAt;          // When did the track start playing
    this.paused              = true; // is the track paused?
    
    // ARRAYS
    this.audios              = []; 
    this.buffer              = []; 
    this.bouncerleft         = []; 
    this.bouncerright        = []; 
    
};



Jcmix.prototype = { 

    /**
     * INIT
     * Initialization
     *
     **/
    init: function(){
        this.addStylesheet();
        this.createLoader();
        this.buildTracks(); // get user tracks from frontend        
        this.bindMouseandTouch();
    },

     /**
     * BUILD TRACKS
     * build the tracks from the frontend
     *
     **/

    buildTracks: function(){
      var that = this;
      var count = 0;
      this.JCmix.children('.track').each(function(){
        count++;
        that.urlList.push($(this).attr('data-url'));
        if(count == that.JCmix.children('.track').length)
          that.setupAudioContext();
      });
    },

    /**
     * SETUP AUDIO CONTEXT
     * setup the audio context
     *
     **/

    setupAudioContext: function(){

      // Fix up prefixing
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      if(window.AudioContext){
        this.context = new AudioContext();
        this.load();
      }else{

        var head      = document.getElementsByTagName('head')[0];
        var stylesheet    = document.createElement('link');
        stylesheet.rel = "stylesheet";
        stylesheet.type= "text/css"; 
        stylesheet.href= "https://fonts.googleapis.com/css?family=Open+Sans";
        head.appendChild(stylesheet);
        var nosupport = document.createElement("div");
        nosupport.id = "nosupport";
        nosupport.innerHTML = "You are using a browser that does not support HTML5 Audio. <br><a href='https://www.google.com/chrome/' target='_blank'>Click here to download Google Chrome, and experience a better web.</a><br><br><a href='#' onclick='closeSupport()'>Close This Message</a>";
        document.body.appendChild(nosupport);
      }

    },

     /**
     * ADD STYLESHEET
     * add in the style sheet to the header
     *
     **/
    addStylesheet: function(){
      /*var stylesheet    = document.createElement('link');
      stylesheet.rel = "stylesheet";
      stylesheet.type= "text/css"; 
      stylesheet.href= this.stylesheetURL;
      this.DOMhead.append(stylesheet);*/

      // fonts
      var stylesheet    = document.createElement('link');
      stylesheet.rel = "stylesheet";
      stylesheet.type= "text/css"; 
      stylesheet.href= "https://fonts.googleapis.com/css?family=Open+Sans";
      this.DOMhead.append(stylesheet);

      // fonts
      var stylesheet    = document.createElement('link');
      stylesheet.rel = "stylesheet";
      stylesheet.type= "text/css"; 
      stylesheet.href= "https://fonts.googleapis.com/css?family=Share+Tech+Mono";
      this.DOMhead.append(stylesheet);

    },
    /**
     * CREATE LOADER
     * create the loader div
     *
     **/


    createLoader: function(){
      var loader = "<div class=\"loader\">";
      loader     +=   "<div class=\"loader-inner ball-clip-rotate-multiple\">";
      loader     +=      "<div></div>";
      loader     +=      "<div></div>";
      loader     +=   "</div>";
      loader     +=    "<p>Loading... <span>0</span>%</p>";
      loader     += "</div>";

      this.JCmix.append(loader); // Aoppend to body
      this.percentLoaded = $('.loader p span'); // create the DOM element
      this.loader = $('.loader');
    },

     /**
     * HIDE LOADER 
     * Hide the laoding screen once loaded
     *
     **/

    hideLoader: function(){
      var that = this;
        this.loader.hide()
        that.resizeLabels();
        that.mixer.fadeTo('slow', 1);
        
    },


    /**
     * RESIZE LABELS
     * resize labels so that they all the same
     *
     **/

    resizeLabels: function(){

      var height = 0;
       $('.track-label').each(function(){
        if($(this).height() > height)
         $('.track-label').css('height', $(this).height());
       });

    },

    /**
     * BUILD FRONTEND 
     * Build frontend elements after loading
     *
     **/

    buildFrontEnd: function(){

      this.createMixer();
      this.createTransport();
      this.addInfo();

    },

    /**
     * ADD INFO TEXT
     * add ino text to bottom
     *
     **/

    addInfo: function(){

      var info = "<p id=\"info\">";
          info +=      "Built by <a href='http://www.juliancole.co.uk' target='_blank'>Julian Cole</a> for <a href='http://www.realstrings.com' target='_blank'>Realstrings.com</a>"
          info += "</p>";

      this.mixer.append(info); // Aoppend to body

    },

    /**
     * CREATE TRANSPORT
     * create transport element
     *
     **/

    createTransport: function(){
      if(this.JCmix.attr('data-record') == 'true')
        this.allowRecord = true;
      var transport = "<div id=\"transport\">";
      transport +=      "<a type=\"button\" id=\"play\">";
      transport +=      "<a type=\"button\" id=\"start\">";
      if(this.allowRecord)
        transport +=      "<a type=\"button\" id=\"record\">";
      transport +=    "</div>";


      this.mixer.append(transport); // Aoppend to body
      this.transport = $('#transport');
      this.playButton = $('#play'); // create the DOM element
      this.startButton = $('#start');

      var that = this;

      // TRANSPORT CLICK HANDLERS
      this.playButton.on('click',function(e) {
          e.preventDefault();
          if(this.dataset.state == "pause"){
              this.dataset.state = "play";
              $(this).removeClass('active');
          }
          else{
              this.dataset.state = "pause";
              $(this).addClass('active');
          }
          that.togglePlay(this.dataset.state);
      });

      if(this.allowRecord){ // only do this if allowing recording
        this.transport.addClass('recording');
        this.mixer.append("<div id=\"recordListButtons\"><a type=\"button\" id=\"toggleRecordings\" >Hide Recordings<a><a type=\"button\" id=\"clearRecordings\" >Clear Recordings<a></div>"); // Aoppend to body
        this.mixer.append("<ul id=\"recordinglist\"></ul>"); // Aoppend to body
        this.recordButton = $('#record');
        this.recordinglist = $('#recordinglist');
        this.toggleRecordingsButton = $('#toggleRecordings');
        this.clearRecordingsButton = $('#clearRecordings');

        this.recordButton.on('click',function(e) {
            if(!that.paused) //can't toggle if currenty playing
              return false;
            e.preventDefault();
            if(this.dataset.state == 'record'){
                this.dataset.state = 'non';
                $(this).removeClass('active');
                that.recording = false;
            }
            else{
                this.dataset.state = 'record';
                $(this).addClass('active');
                that.recording = true;
            }
        });

        this.toggleRecordingsButton.on('click',function(e) {
            if($(this).html() == 'Hide Recordings'){
              $(this).html('Show Recordings');
              that.recordinglist.slideUp();
            }
            else{
              $(this).html('Hide Recordings');
              that.recordinglist.slideDown();
            }

        });

        this.clearRecordingsButton.on('click',function(e) {
            that.recordinglist.html('');
        });
      }

      this.startButton.on('click', function(){
        that.rewindToBeginning();  
      });

    },

    /**
     * CREATE MIXER
     * create mixer element
     *
     **/

    createMixer: function(){
          this.title = this.JCmix.attr('data-title');
          var mixer = "<div id=\"mixer\">";
          mixer +=      "<h1>"+this.title+"</h1>";
          mixer +=      "<div id=\"channelstrip\"></div>";
          mixer +=      "<div class=\"paddingholder\">";
          mixer +=        "<div id=\"timer\">";
          mixer +=          "<span class=\"progress\">00:00:000</span>";
          mixer +=          "<span> / </span> ";
          mixer +=          "<span class=\"total\"></span>";
          mixer +=        "</div>";
          mixer +=      "</div>";
          mixer +=      "<div class=\"paddingholder\">";
          mixer +=        "<div id=\"timeline\">";
          mixer +=          "<div id=\"progress\" ></div>";
          mixer +=        "</div>";
          mixer +=      "</div>";
          mixer +=    "</div>";

          this.JCmix.append(mixer); // Aoppend to body
          // create the DOM elements
          this.mixer         = $('#mixer');
          this.channelStrip  = $('#channelstrip');
          this.timer         = $('#timer');
          this.timerProgress = $('#timer .progress');
          this.timerTotal    = $('#timer .total');
          this.timeline      = $('#timeline');
          this.progressbar   = $('#progress');
          
          this.setTransportEventHandlers();
    },

    /**
     * SET TRANSPORT EVENT HANDLERS
     * set event handlers for dragging the transport bar
     *
     **/

    setTransportEventHandlers: function(){
        var that = this;
       // timeline dragging event handlers
        this.DOMdocument.on(TouchMouseEvent.DOWN,function(e){

            if( e.target.id=="timeline" || e.target.id=="progress"){
              that.timelinedrag = true;
              that.fingerPosition = e.pageX-parseFloat(that.JCmix.offset().left); // distance from side of timeline to page
              $(this).on(TouchMouseEvent.MOVE,function(e){
                that.fingerPosition = e.pageX-parseFloat(that.JCmix.offset().left); // distance from side of timeline to page
                that.timelineClick(that.fingerPosition, false);
              });

              that.DOMdocument.on(TouchMouseEvent.UP, function(e){

                  $(this).unbind(TouchMouseEvent.MOVE);
                  $(this).unbind(TouchMouseEvent.UP);
                  that.timelinedrag = false;
                  //if( e.target.id=="timeline" || e.target.id=="progress"){

                  if(e.pageX !== null)
                    distance = e.pageX-parseFloat(that.JCmix.offset().left);

                    that.timelineClick(that.fingerPosition, true);
                  //}
                  
              });
            }
        });

        // on slider changes
        this.DOMbody.on('change', 'input[type=range][data-slider]', function() {
           that.sliderChange($(this).attr('data-slider'), $(this).val()); 
        });

        // on mute changes
        this.DOMbody.on('change', 'input[data-mute]', function() {
           that.muteChange($(this).attr('data-mute'), $(this).is(":checked"));
        });

        // on panner changes
        /*this.DOMbody.on('change', 'input[data-panner]', function() {
           that.panChange($(this).attr('data-panner'), $(this).val());
        });*/

    },


    getHighestVolume: function(array){
      return Math.max.apply( Math, array );
    },

 
    getAverageVolume: function(array) {
        var values = 0;
        var average;

        var length = array.length;

        // get all the frequency amplitudes
        for (var i = 0; i < length; i++) {
            values += array[i];
        }

        average = values / length;
        return average;
    },

     /**
    * UPDATE LOADING PERCENT
    * Update the percent loaded
    *
    **/

    updateLoadingPercent: function(){
      var percent = (100 / this.urlList.length) * this.loadCount;
      this.percentLoaded.html(Math.round(percent));
    },


    /**
    * LOAD BUFFER
    * Get the audio files
    *
    **/


    loadBuffer: function(url, index) {
      // Load buffer asynchronously
      var request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "arraybuffer";

      var loader = this;

      request.onload = function() {
        // Asynchronously decode the audio file data in request.response
        loader.context.decodeAudioData(
          request.response,
          function(buffer) {
            if (!buffer) {
              alert('error decoding file data: ' + url);
              return;
            }
            loader.bufferList[index] = buffer;
            if (++loader.loadCount == loader.urlList.length)
              loader.finishedLoading(loader.bufferList);

            loader.updateLoadingPercent();
          },
          function(error) {
            console.error('decodeAudioData error', error);
          }
        );
      }

      request.onerror = function() {
        //  alert('Failed to load audio. Ensure that your audio files are hosted on the same server as your website, or that you allow cross origin resource sharing (CORS) if you are hosting them on a different server.');
      }

      request.send();
    },

    /**
    * LOAD
    * Ready the audio 
    *
    **/

    load: function() {
      for (var i = 0; i < this.urlList.length; ++i)
        this.loadBuffer(this.urlList[i], i);
    },

    /**
    * SET DEFAULT VALUES
    * Set values as defined by user
    *
    **/

    setDefaultValues: function(){

      var that = this;
      var count = 0;
      this.JCmix.children('.track').each(function(){
        // change mute attribute
        var mute = $(this).attr('data-start-muted');
        if(mute == 'true')
          $('[data-mute='+count+']').prop('checked', true).trigger('change');

        // change iniital volume attribute
        var initialVol = $(this).attr('data-initial-volume');
        $('[data-slider='+count+']').val(initialVol).trigger('change');

        var initialPan = $(this).attr('data-initial-pan');
        $('[data-panner='+count+']').val(initialPan).trigger('change');


        var label = $(this).attr('data-label');
        $('[data-label='+count+']').html(label);

        count++;
        // we've done with the default divs now, so we can delete them
        if(count == that.JCmix.children('.track').length)
          that.deleteDefaultDivs();
        $('[data-label='+count+']').html('Master');

        

      });

    },

    /**
    * DELETE DEFAULT DIVS
    * Delete use setting divs.
    *
    **/

    deleteDefaultDivs: function(){
      this.JCmix.children('.track').each(function(){
        $(this).remove();
      });

    },

    /**
    * FINISHED LOADING 
    * Buffer the audio
    *
    **/

    finishedLoading: function (bufferList) {

      this.buildFrontEnd();
      this.buffer = [];
      for (var i = 0; i < this.urlList.length; i++){
        this.buffer.push(bufferList[i]);
      }
      this.createElements(); // remove for autoplay;
      this.setDefaultValues();
      this.setDuration(); // set duration of piece
      this.hideLoader();

    }, 

    /**
    * Build a new meter
    * Buffer the audio
    *
    **/

    createMeter: function(i)
    {

      // Create left analyser
      var analyser = this.context.createAnalyser();
      this.leftAnalysers.push(analyser);
      this.leftAnalysers[i].smoothingTimeConstant = 0.3;
      this.leftAnalysers[i].fftSize = 1024;

      // Create right analyser
      var analyser2 = this.context.createAnalyser();
      this.rightAnalysers.push(analyser2);
      this.rightAnalysers[i].smoothingTimeConstant = 0.0;
      this.rightAnalysers[i].fftSize = 1024;

      // setup a javascript node
      // connect to destination, else it isn't called

      // create [a buffer source node
      var splitter = this.context.createChannelSplitter();
      this.splitters.push(splitter);

      // connect the source to the analyser and the splitter
      if(i < this.pannerNodes.length-1) // if this is a regular channels trip
        this.pannerNodes[i].connect(this.splitters[i]);
      else // master channel doesn't use a panner
        this.gainNodes[i].connect(this.splitters[i]);

      // connect one of the outputs from the splitter to
      // the analyser
      this.splitters[i].connect(this.leftAnalysers[i],0,0);
      this.splitters[i].connect(this.rightAnalysers[i],1,0);

      // connect the splitter to the javascriptnode
      // we use the javascript node to draw at a
      // specific interval.
      this.leftAnalysers[i].connect(this.javascriptNode[i]);


      this.javascriptNode[i].connect(this.context.destination);

      if(i < this.pannerNodes.length-1){ // if this is a regular channels trip
        this.pannerNodes[i].connect(this.gainNodes[this.gainNodes.length-1]);
      }
      else{ // this is the master chjannel strip
        this.gainNodes[i].connect(this.context.destination);
        this.rec = new Recorder(this.gainNodes[i]);
      }

      this.drawCanvas(this.leftAnalysers[i], this.rightAnalysers[i], this, i); 

    },

 
    


    /*
    * SLIDER CHANGE
    * Event for when a slider changes
    */

    sliderChange: function(audio_id,value){

      if(!this.muted[audio_id])
        this.gainNodes[audio_id].gain.value = value;
      
      this.gainValues[audio_id] = value; // store gain value

    },

    /*
    * MUTE CHANGE
    * Event when mute changes
    */

    muteChange: function(audio_id,value){
      if(value){
        this.gainValues[audio_id] = this.gainNodes[audio_id].gain.value; // store gain value
        this.gainNodes[audio_id].gain.value = 0; // mute the gain node
        this.muted[audio_id] = true;
      }
      else{
        this.muted[audio_id] = false;
        this.gainNodes[audio_id].gain.value = this.gainValues[audio_id]; // restore previous gain value
      }

    },

    /* PAD 
    * pad string with leading zeros
    */

    pad: function(str, max) {
        str = str.toString();
        return str.length < max ? this.pad("0" + str, max) : str;
    },

    /* 
    * FORMAT TIME
    * Convert milliseconds to readable format
    */

    formatTime: function(millis){

      
      var hours = Math.floor(millis / 36e5),
          mins = Math.floor((millis % 36e5) / 6e4),
          secs = Math.floor((millis % 6e4) / 1000);
          mill = Math.floor(millis % 1000);
          var returns = "<span>"+this.pad(mins,2)+"</span>:<span>"+this.pad(secs,2)+"</span>:<span>"+this.pad(mill, 2).substring(2, 0);+"</span>";
        return returns;
    },

    /* 
    * COUNT LOOP
    * count the number of loops
    */

    countLoops: function(current){

      this.loops = Math.floor(current/this.totalLength);
      this.currentLoopProgress= (Date.now() - this.startedAt)-(this.loops*(this.buffer[0].duration*1000));

    }, 


    /* 
    * UPDATE TIMER
    * Update time to current starte
    */

    updateTimer: function(){

      var current = this.formatTime(this.currentLoopProgress);
      var totalLength = this.formatTime(this.totalLength); 
      this.timerProgress.html(current);
      this.timerTotal.html(totalLength);
      this.updateTimeline();

    },


    /*
    * Update position of progress bar
    */

    updateTimeline: function(){

      if(this.timelinedrag)
        return false;


      var current = Date.now() - this.startedAt;
      this.countLoops(current); // work out number of times looped
      var percent = (100/this.totalLength)*this.currentLoopProgress;
      var left = (this.timeline.width()/100) * percent;
      this.progressbar.css('left',left);

    },

    

    /* 
    * DRAW CANVAS
    * Create the listener which draws the sound bar onto the canvas
    */

    drawCanvas: function(leftAnalyser, rightAnalyser, ctx, i)
    {

        var canvas =  $('#c'+i).get()[0].getContext("2d");
        ctx.bouncerleft.push({ "average":0 , "opacity":1 });
        ctx.bouncerright.push({ "average":0 , "opacity":1 });
        gradient = canvas.createLinearGradient(0,0,0,400);
        gradient.addColorStop(1,'#31e2fc');
        gradient.addColorStop(0.75,'#38fedd');
        gradient.addColorStop(0.25,'#38fedd');
        gradient.addColorStop(0,'#31e0fc');

        // when the javascript node is called
        // we use information from the analyzer node
        // to draw the volume
        ctx.javascriptNode[i].onaudioprocess = function(event) {

            ctx.updateTimer();

            // get the average for the first channel
            var array =  new Uint8Array(leftAnalyser.frequencyBinCount);
            leftAnalyser.getByteFrequencyData(array);
            var average = ctx.getAverageVolume(array);

            // get the average for the second channel
            var array2 =  new Uint8Array(rightAnalyser.frequencyBinCount);
            rightAnalyser.getByteFrequencyData(array2);
            var average2 = ctx.getAverageVolume(array2);

            // bouncers left
            if(average > ctx.bouncerleft[i].average){
              ctx.bouncerleft[i].average = average;
              ctx.bouncerleft[i].opacity = 1;
            }
            else{
              if(ctx.bouncerleft[i].opacity > 0.1) // fade out
                ctx.bouncerleft[i].opacity = ctx.bouncerleft[i].opacity -0.1;
              else
                ctx.bouncerleft[i].opacity = 0;
              ctx.bouncerleft[i].average--; // make it fall
            }

            // bouncers right
            if(average2 > ctx.bouncerright[i].average){
              ctx.bouncerright[i].opacity = 1;
              ctx.bouncerright[i].average = average2;
            }
            else{
              if(ctx.bouncerright[i].opacity > 0.1)// fade out
                ctx.bouncerright[i].opacity = ctx.bouncerright[i].opacity -0.1;
              else
                ctx.bouncerright[i].opacity = 0;
              ctx.bouncerright[i].average--;// make it fall
            }


            // clear the current state
            canvas.clearRect(0, 0, 60, ctx.meterHeight);

            canvas.fillStyle="#15181b";

            // create background to meters
            canvas.fillRect(0,0,ctx.meterWidth,ctx.meterHeight+200);
            canvas.fillRect(ctx.meterWidth+5,0,ctx.meterWidth,ctx.meterHeight+200);

            // set the fill style
            canvas.fillStyle=gradient;
            // create the delayed max meter bar
            if(average > 0)
              canvas.fillRect(0,ctx.meterHeight-(ctx.bouncerleft[i].average*(ctx.meterHeight/100))-2,ctx.meterWidth,ctx.bouncerleft[i].opacity);
            if(average2 > 0)
              canvas.fillRect(ctx.meterWidth+5,ctx.meterHeight-(ctx.bouncerright[i].average*(ctx.meterHeight/100))-2,ctx.meterWidth,ctx.bouncerright[i].opacity);

            // create the meters (ctx.meterHeight/100) is 1% of the meter height
            canvas.fillRect(0,ctx.meterHeight-(average*(ctx.meterHeight/100)),ctx.meterWidth,ctx.meterHeight+200);
            canvas.fillRect(ctx.meterWidth+5,ctx.meterHeight-(average2*(ctx.meterHeight/100)),ctx.meterWidth,ctx.meterHeight+200);

            if(parseFloat(Date.now() - ctx.startedAt)+50 >= parseFloat(ctx.totalLength)){ // disable looping for now
                $('#play').removeClass('active');
                ctx.stop();
                ctx.rewindToBeginning();
              return false;
            }

        }

    },

    panChange: function(audio_id,value) {
        var xDeg = parseInt(value);
        var zDeg = xDeg + 90;
        if (zDeg > 90) {
          zDeg = 180 - zDeg;
        }
        var x = Math.sin(xDeg * (Math.PI / 180));
        var z = Math.sin(zDeg * (Math.PI / 180));
        this.pannerNodes[audio_id].setPosition(x, 0, z);
      },


    /**
    * CREATE GAIN NODES
    * Create a gain node for index
    *
    **/

    createGainNode: function(i, master){

      // Create a gain node.
      var gainnode = this.context.createGain();
      var pannernode = this.context.createPanner();
      pannernode.panningModel = "equalpower";
      this.gainValues.push(1);
      this.muted.push(false);
      this.gainNodes.push(gainnode);
      this.pannerNodes.push(pannernode);
      // Connect the source to the gain node.
      if(!master){
       // this.audios[i].connect(this.pannerNodes[i]);
       // this.pannerNodes[i].connect(this.gainNodes[i]);
        this.audios[i].connect(this.gainNodes[i]);
        this.gainNodes[i].connect(this.pannerNodes[i]);

      }

    //  this.panChange(i,0); // set pan to 0

      this.DOMbody.find("[data-panner="+i+"]").trigger('change');
      $("[data-slider="+i+"]").trigger('change'); // set gain note to value of visual slider
      $("[data-mute="+i+"]").trigger('change'); // set gain note to value of mute


    },


    /**
    * RESERT ARRAYS
    * Reset arrays to defaults
    *
    **/

    resetArrays: function(){

      this.bouncerleft = [];
      this.bouncerright = [];
      this.leftAnalysers = [];
      this.rightAnalysers = [];
      this.splitters = [];

      this.audios = [];
      this.gainNodes = [];
      this.pannerNodes = [];
      this.gainValues = [];
      this.javascriptNode = [];
      this.muted = [];
      this.loop = 0; // reset loops loops
    },

    /**
    * Load visuals and audio nodes
    *
    **/

    createElements: function(){
      this.resetArrays();
       // Create two sources and play them both together.
      for (var i = 0; i < this.urlList.length; i++){
        var source1 = this.context.createBufferSource();
        source1.buffer = this.buffer[i];

          source1.loop = false; // flase to stop looping

        this.audios.push(source1);

        this.audios[i].muted = true;
        // create a new javascript node ready for the meter
        javascriptNode = this.context.createScriptProcessor(2048, 1, 1);
        // create a gain controller
        this.createGainNode(i, false);
        // Connect the gain node to the destination.
        this.javascriptNode.push(javascriptNode);
        // create a meter visualisation

        var channel = this.createChannel(i);
        // create a new canvas
        var canvas = this.createCanvas(i, channel);
        // create Sliders
        this.createSlider(i, channel);
        this.createMute(i, channel);
        this.createPanner(i, channel);
        this.createLabel(i, channel);

      }

      // Create master elements
      javascriptNode = this.context.createScriptProcessor(2048, 1, 1);
      this.createGainNode(i, true);
      this.javascriptNode.push(javascriptNode);
      var channel = this.createChannel(i);
      // create a new canvas
      var canvas = this.createCanvas(i, channel);
      // create Sliders
      this.createSlider(i, channel);
      this.createMute(i, channel);
      this.createLabel(i, channel);

     

    },

    /**
    * SET DURATION 
    * set dutaion of timeline to the length of the ongest buffer
    *
    **/

    setDuration: function(){

      for (var i = 0; i < this.audios.length; i++){
        if((this.buffer[i].duration * 1000) > this.totalLength)
          this.totalLength = this.buffer[i].duration * 1000; // tottal length of buffer in milliseconds
      } 

      this.updateTimer(); // update visual timer

    },

    /**
    * PLAY
    * Play the audio
    *
    **/

    play: function(){

      this.paused = false;
      this.createElements();
      this.replay = false;
      this.playing = [];
      for (var i = 0; i < this.audios.length; i++){

        this.createMeter(i);
        // start from paused time
        if (this.currentLoopProgress) {
          this.startedAt = Date.now() - this.currentLoopProgress;
          var startFrom =  this.currentLoopProgress / 1000;
          if(this.buffer[i].duration > startFrom){
              this.playing.push(i);
              this.audios[i].start(0, this.currentLoopProgress / 1000);
          }
        }
        // start from beginning
        else {
          this.playing.push(i);
          this.startedAt = Date.now();
          this.audios[i].start(0);
        }
      }
      this.createMeter(i); // create master meter
      //on end of source, disconnect everything
      var that = this;
      $(this.audios).each(function(index){
        this.onended = function() {
          that.onended(index);
        }
      });

      if(this.recording)
        this.startRecording();
     
    },

    /**
    * START RECORDING
    * Start recording the audio stream
    *
    **/

    startRecording: function(){
      this.rec.clear();
      this.rec.record();
    },

    onended: function(index){


      if(this.playing.indexOf(index) > -1){
        // disconnect everything
        this.javascriptNode[index].disconnect();
        this.audios[index].disconnect();
        this.gainNodes[index].disconnect();
        this.pannerNodes[index].disconnect();
        this.javascriptNode[index].disconnect();
        this.leftAnalysers[index].disconnect();
        this.rightAnalysers[index].disconnect();
        this.splitters[index].disconnect();
        var number = this.playing.indexOf(index);
        this.playing.splice(number, 1);
        this.clearCanvas(index);
      }

      // disconnect everything
      if(this.playing.length == 0 && this.paused){

        for (var i = 0; i < this.gainNodes.length; i++){
          this.gainNodes[i].disconnect();
          this.pannerNodes[i].disconnect();
          this.clearCanvas(i);

        } 
        for (var i = 0; i < this.javascriptNode.length; i++){
          this.javascriptNode[i].disconnect();
        } 
        for (var i = 0; i < this.leftAnalysers.length; i++){
          this.leftAnalysers[i].disconnect();
        } 
        for (var i = 0; i < this.rightAnalysers.length; i++){
          this.rightAnalysers[i].disconnect();
        } 
        for (var i = 0; i < this.splitters.length; i++){
          this.splitters[i].disconnect();
        } 
       

       /* this.gainNodes[this.gainNodes.length-1].disconnect();
        this.javascriptNode[this.gainNodes.length-1].disconnect();
        this.leftAnalysers[this.gainNodes.length-1].disconnect();
        this.rightAnalysers[this.gainNodes.length-1].disconnect();
        this.splitters[this.gainNodes.length-1].disconnect();*/



        if(this.replay){ // replay if this is a timeline skip rather than a full on stop
          var percent = (100/this.totalLength)*this.replayFrom;
          var left = (this.timeline.width()/100) * percent; 
          this.progressbar.css('left',left); 
          this.currentLoopProgress = this.replayFrom;
          this.play(); 
        }
      }
      return true;
    },



    /**
    * Clear Canvas
    * clear the canvas from index
    *
    **/

    clearCanvas: function(index){
    
        var canvas = $('#c'+index).get()[0].getContext("2d");
        // clear canvas
        canvas.clearRect(0, 0, 60, 400);
        //rebuild background
        canvas.fillStyle="#15181b";
        canvas.fillRect(0,0,this.meterWidth,this.meterHeight+200);
        canvas.fillRect(this.meterWidth+5,0,this.meterWidth,this.meterHeight+200);

    },

    /**
    * PAUSE
    * Pause the audio
    *
    **/

    stop: function(){

      if(this.recording && !this.paused){

        this.rec.stop();
        this.toggleRecordingsButton.show();
        this.clearRecordingsButton.show();
        this.rec.exportWAV(function(blob) {
          var url = URL.createObjectURL(blob);
          var li = document.createElement('li');
          var au = document.createElement('audio');
          var hf = document.createElement('a');
          var src = document.createElement('source');

          au.controls = true;
          src.src = url;
          src.type = "audio/wav";
          au.appendChild(src);
          hf.href = url;
          hf.download = that.title+' ' +new Date().toISOString() + '.wav';
          hf.innerHTML = hf.download;
          li.appendChild(au);
          li.appendChild(hf);
          that.recordinglist.append(li);
        });
      }

      this.paused = true;
      var that = this;
      for (var i = 0; i < this.audios.length; i++){

        if(this.playing.indexOf(i) > -1)
          this.audios[i].stop(0);
        
        this.currentLoopProgress = Date.now() - this.startedAt;

      }

      

    },

   

    /**
    * Toggle the play state
    * play mix
    *
    **/

    togglePlay: function(state){
      if(state == 'pause')
        this.play();
      else
        this.stop();           
    },

  

    /*
    * CREATE CHANNEL
    * CREATE CHANNEL STRIP
    */

    createChannel: function(i){
      if($('div[data-channel="'+i+'"]').length == 0){
         var newdiv = document.createElement('div');
         newdiv.dataset.channel = i;
        this.channelStrip.append(newdiv);
      }
      return $('div[data-channel="'+i+'"]');
    },

    /*
    * CREATE MUTE
    * CREATE MUTE BUTTON
    */

    createMute: function(i, channel){

      if ($('input[data-mute="'+i+'"]').length == 0){
         var mute = document.createElement('div');
         mute.className = 'mute-button';
         mute.innerHTML = "<label><input data-mute='"+i+"' type='checkbox' value='1' ><span>M</span></label>";
         $(channel).append(mute);
      }

      return $('input[data-mute="'+i+'"]');

    },


    /*
    * CREATE PANNER
    * CREATE PANNER BUTTON
    */

    createPanner: function(i, channel){
      var that = this;

      if ($('input[data-panner="'+i+'"]').length == 0){
         var panner = document.createElement('div');
         panner.className = 'panner-range';
         panner.innerHTML = "<input data-panner='"+i+"' value='0' class='dial' type='text'>";
         $(channel).append(panner);
         $("[data-panner='"+i+"']").knob({
            'min':-90,
            'max':90,
            'width' : 30,
            'height' : 30,
            'fgColor' : '#FFF',
            'angleOffset': '-125',
            'bgColor' : '#951717',
            'angleArc': '250',
            'skin' :'tron',
            'thickness':'.2',
            'displayPrevious' : true,
            'cursor':'30',
            'draw' : function () { that.panChange(i,this.v); }

         });
      }

      return $('input[data-panner="'+i+'"]');

    },

    /*
    * CREATE LABEL
    * CREATE Label for track
    */

    createLabel: function(i, channel){


      if ($('[data-label="'+i+'"]').length == 0){
         var label = document.createElement('div');
         label.className = 'track-label';
         label.innerHTML = "<label data-label='"+i+"'></label>";
        
         $(channel).append(label);
      }

      // resize so all labels are the correct height

     

      return $('[data-label="'+i+'"]');

    },

    /*
    * CREATE SLIDER
    * CREATE VOLUME SLIDER
    */

    createSlider: function(i, channel){

      if ($('input[data-slider="'+i+'"]').length == 0){
         var slider = document.createElement('div');
         slider.className = 'slider';

          var input = document.createElement("input");
          input.type = "range";
          input.dataset.slider = i;
          input.min = 0;
          input.max = 1.5; 
          input.step = 0.01;
          input.addEventListener('input', function() {
            this.setAttribute('value', this.value);
            $(this).trigger('change');
          });

         $(slider).append(input);
         $(channel).append(slider);
      } 

      return $('input[data-slider="'+i+'"]');

    },


    

    /*
    * CREATE CANVAS
    * Create a new canvas element for channel
    */

    createCanvas: function(i, channel){

      if ($('#c'+i).length == 0){
        var mycanvas = document.createElement("canvas");
        mycanvas.id = "c"+i;
        mycanvas.className = "meter";
        mycanvas.width = (this.meterWidth*2)+5;
        mycanvas.height = this.meterHeight;
        channel.append(mycanvas);

        // create background to meters
        var canvas =  $('#c'+i).get()[0].getContext("2d");
        canvas.fillStyle="#15181b";
        canvas.fillRect(0,0,this.meterWidth,this.meterHeight+200);
        canvas.fillRect(this.meterWidth+5,0,this.meterWidth,this.meterHeight+200);
      } 

      return $('#c'+i).get()[0].getContext("2d");
      
    },

    /*
    * REWIND TO BEGINNING
    * Rewind track to beginning
    */

    rewindToBeginning: function(){

      if(this.paused){
        this.playButton.attr('data-state', 'play');
      }
      
      this.progressbar.css('left',0); 
      this.currentLoopProgress = 0;
      this.replayFrom = 0;
      if(!this.paused){
        this.replay = true;
        this.replayFrom = 0;
        this.stop();   
      }

    },

    /*
    * TIMELINE CLICK
    * start playign from where the user clicked the timeline
    */

    timelineClick: function(position, audio){

      var paused = this.paused;
      var percent = (100/this.timeline.width()) * position;

      if(percent < 1 || percent > 99) // only if mouse inside box
        return false;

      var pausedAt = (this.totalLength/100) * percent;
      this.currentLoopProgress = pausedAt;
      // reposition progress bar
      var percent = (100/this.totalLength)*pausedAt;
      var left = (this.timeline.width()/100) * percent; 
      this.progressbar.css('left',left); 
      var that = this;

      if(!paused && audio){
        this.replay = true;
        this.replayFrom = pausedAt;
        this.stop();  
      }
      
        
      
    },

    /*
    * Bind mouse clciks and screen touches
    */

    bindMouseandTouch: function(){
       /* == GLOBAL DECLERATIONS == */
      TouchMouseEvent = {
          DOWN: "touchmousedown",
          UP: "touchmouseup",
          MOVE: "touchmousemove"
      }
     
      /* == EVENT LISTENERS == */
      var onMouseEvent = function(event) {
          var type;
          
          switch (event.type) {
              case "mousedown": type = TouchMouseEvent.DOWN; break;
              case "mouseup":   type = TouchMouseEvent.UP;   break;
              case "mousemove": type = TouchMouseEvent.MOVE; break;
              default: 
                  return;
          }
          
          var touchMouseEvent = normalizeEvent(type, event, event.pageX, event.pageY);      
          $(event.target).trigger(touchMouseEvent); 
      }
      
      var onTouchEvent = function(event) {
          var type;
          
          switch (event.type) {
              case "touchstart": type = TouchMouseEvent.DOWN; break;
              case "touchend":   type = TouchMouseEvent.UP;   break;
              case "touchmove":  type = TouchMouseEvent.MOVE; break;
              default: 
                  return;
          }
          
          var touch = event.originalEvent.touches[0];
          var touchMouseEvent;
          
          if (type == TouchMouseEvent.UP) 
              touchMouseEvent = normalizeEvent(type, event, null, null);
          else 
              touchMouseEvent = normalizeEvent(type, event, touch.pageX, touch.pageY);
          
          $(event.target).trigger(touchMouseEvent); 
      }
      
      /* == NORMALIZE == */
      var normalizeEvent = function(type, original, x, y) {
          return $.Event(type, {
              pageX: x,
              pageY: y,
              originalEvent: original
          });
      }
      
      /* == LISTEN TO ORIGINAL EVENT == */
      var jQueryDocument = $(document);
     
      if ("ontouchstart" in window) {
          jQueryDocument.on("touchstart", onTouchEvent);
          jQueryDocument.on("touchmove", onTouchEvent);
          jQueryDocument.on("touchend", onTouchEvent); 
      } else {
          jQueryDocument.on("mousedown", onMouseEvent);
          jQueryDocument.on("mouseup", onMouseEvent);
          jQueryDocument.on("mousemove", onMouseEvent);
      }
    }



}; // End Class

// check browser version
function get_browser_info(){
    var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
    if(/trident/i.test(M[1])){
        tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
        return {name:'IE',version:(tem[1]||'')};
        }   
    if(M[1]==='Chrome'){
        tem=ua.match(/\bOPR\/(\d+)/)
        if(tem!=null)   {return {name:'Opera', version:tem[1]};}
        }   
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
    return {
      name: M[0],
      version: M[1]
    };
 }


function closeSupport(){
    $("#nosupport").remove();
}

//include('https://ajax.googleapis.com/ajax/libs/jquery/2.1.3/jquery.min.js', run);

// Run the mixer
function run(){
  var jcmix = new Jcmix();
  jcmix.init(); 
  $('#panner').on('change', function(){
    jcmix.pan(this);

  });
}

// load jquery if not already loaded
function include(file, callback) {

  if (typeof jQuery == 'undefined') {  
    var head      = document.getElementsByTagName('head')[0];
    var script    = document.createElement('script');
    script.type   = 'text/javascript';
    script.src    = file;
    script.onload = script.onreadystatechange = function() {
      // execute dependent code
      if (callback) callback();
      // prevent memory leak in IE
      head.removeChild(script);
      script.onload = null;
    };
    head.appendChild(script);
  }else{
    if (callback) callback();
  }
}



